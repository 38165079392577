import React from "react";
import { Link } from "react-router-dom";
import footerbg from '../../images/footerbg.png'
import logowhite from '../../images/logo-text-white.png'

const  FooterBlock= ()=> {

    return(
        <div
         className="footerHeightMobile"

        style={{ 
    
            backgroundImage: `  url(${footerbg})`,
           
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            
            height: 'auto',
            paddingBottom:'100px',
           
        
        }}
         
         
         
         
         >
            <div className=" uk-container uk-margin-large-top">
           <div  className="uk-grid uk-margin-large-top" data-uk-grid>
                <div className="uk-width-1-3@s">
                    <div className=" uk-margin-medium-top ">
                         <img src={logowhite} className="foooterLogo"/>
                    </div>
                </div>

                <div className="uk-width-1-3@s">
              
                </div>

                <div className="uk-width-1-3@s">
               
                </div>

               
           </div>


            <div className="uk-grid " data-uk-grid style={{position:"relative",top:"-50px"}}>
                <div className="uk-width-1-3@s">
                   <h4 style={{color:"#E6D530", }} className="uk-text-bold">Site Map</h4>
                     
                     <ul className="footermenu">
                      <Link to ="#"><li>About</li></Link>
                      <Link to ="#"> <li> Product & Service</li></Link>
                      <Link to ="#"> <li> Market Place </li></Link>

                     </ul>



                </div>

                <div className="uk-width-1-3@s shiftContactFooter">
                    
                   <h4 style={{color:"#E6D530", }} className="uk-text-bold">Connect with us</h4>
                   <div className="uk-flex">
                   <a href="" uk-icon="mail" className="icon uk-margin-right"></a>
                           <div><p className="light">hello@waaxafrica.com.ng</p></div>
                    
                   </div>


                   <div class="uk-flex uk-margin-top">
                            <a href="" uk-icon="receiver" className="icon uk-margin-right"></a>
                           <div><p className="uk-text-left light">08102961140</p></div>
                     </div> 

                     <div class="uk-flex uk-margin-top">
                            <a href="" uk-icon="home" className="icon uk-margin-right"></a>
                           <div><p  className="uk-text-left light">29, Adebayo Adedeji Street Utako, Abuja.</p></div>
                     </div> 


                </div>

              

            </div>



        </div>



        {/**Social Links**/}
        <div className="uk-container socialFooterDiv uk-margin-medium-top">
           <div className="uk-grid" data-uk-grid>
               <div className="uk-width-1-3@s">
               <div class="uk-flex">
                    <div class="uk-text-bold light">Follow Us</div>
                    <Link to ="" class="uk-icon-button customIcon uk-margin-medium-left  uk-margin-small-right" uk-icon="twitter"></Link>
                    <Link to ="" class="uk-icon-button  customIcon uk-margin-small-right" uk-icon="facebook"></Link>
                    <Link to ="" class="uk-icon-button customIcon" uk-icon="linkedin"></Link>
                    <Link to ="" class="uk-icon-button customIcon uk-margin-small-left "  uk-icon="instagram"></Link>
              </div>
               </div>

               <div className="uk-width-1-3@s">
                 
               </div>

               <div className="uk-width-1-3@s">
               
               </div>
         </div>
          </div>

         <div className="footerBottom uk-margin-large-top">
                   <div className="uk-text-center">
                     <h4 className="light"></h4>
                   </div>
               </div>



        </div>
    )
}


export default FooterBlock