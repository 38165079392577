import React from 'react'
import Layout from '../../Layout/Layout'
import FirstPane from './Blocks/FirstPane'
import Second_pane from './Blocks/Second_pane'
import Third_pane from './Blocks/Third_pane'
import Fourth_pane from './Blocks/Fourth_pane'

import './About.css'
import Fifth_pane from './Blocks/Fifth_pane'

const About = () => {
  return (
   <Layout>
    <FirstPane />
    <Second_pane />
    
    <Third_pane />
    <Fourth_pane />
    <Fifth_pane />
   


    </Layout>
  )
}

export default About