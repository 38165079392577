import React from 'react'
import intro_img from '../../../images/intro_img.png'
import '../Home.css'
import {Link } from 'react-router-dom'

const First_pane = () => {
  return (
    <div>
          <div className='uk-container uk-text-center uk-margin-large-top uk-margin-large-bottom customPaddingLR'>
             <h6 className=''>With challenges such as climate change, population growth together
              with resource scarcity, threaten the sustainability of our food system, that's why
               at WAAX, we are passionate about embracing innovative solutions to address this challenges</h6>
          </div>

        <div className='uk-grid' data-uk-grid>
            <div className='uk-width-1-2@s'>
                <div className=''>
                
                <img src= {intro_img} class="intro_img"/>
              
                </div>
            </div>

            <div className='uk-width-1-2@s'>
                <div className='left_shift_index_div '>
                   <h2 className='uk-text-left  uk-margin-large-top'>Using Machine  Learning for Plant Disease Detection</h2>
                   <p> By collecting data on plant growth, our AI system can help farmers produce crops that are less prone to disease and better
                     adapted to weather conditions  which would help them forcast price.</p>

                    <p className='uk-margin-top'> Our app allow users to upload a photo of a suspected pest or disease, which the AI compares with a database of 50,000 images. 
                    The app also provides analysis and can recommend treatment programs.</p>

                    <Link className="btn uk-margin-large-top" to="/about">Learn More</Link>

                    </div>

                   
            </div>
        </div>
    </div>
  )
}

export default First_pane