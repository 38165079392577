import React from 'react'
import team1 from "../../../images/team1.png"

import team2 from "../../../images/team2.png"
import team3 from '../../../images/janet.jpg'
import team4 from '../../../images/team4.jpg'

const Fifth_pane = () => {
  return (
    <div className='uk-margin-xlarge-top'>

        <div className='uk-container'>
            <div>
                <h2>Our Team</h2>
            </div>

              <div className='uk-grid' data-uk-grid>
                  <div className='uk-width-4-5@s'>
  


                      <div className='team_container'>
                      <div className='uk-grid ' data-uk-grid>
                    <div className='uk-width-1-5@s'>
                     <img src={team1} className='team_members'/>
                   </div>

                 <div className='uk-width-4-5@s'>
                   <h6 className='uk-text-bold'>Michael Ogbonna</h6>
                   <p className='team_role uk-text-small'>Founder & CTO</p>

                   <p className='uk-margin-top uk-text-small'> Michael Ogbonna is an Agricultural and Bio-Resources 
                   Engineer, product designer, and software engineer</p>

                   <p className='uk-text-small uk-margin-small-top'>His journey in agriculture and technology has been driven by a relentless pursuit of 
                    innovation and sustainable solutions. With a background in agricultural engineering and 
                    a knack for product design and software development, I founded WAAX Africa with a clear mission in mind
                     – to revolutionize agriculture and end food scarcity through deep tech, farm mechanization, and e-commerce</p>

                   <p></p>
                 </div>
            </div>
              </div>


</div>



                  <div className='uk-width-1-5@s'>

                  </div>
              </div>



             <div className='uk-grid'>
                 <div className='uk-width-1-5@s'>

                 </div>

                 <div className='uk-width-4-5@s'>
                 <div className='team_container uk-margin-top'>
            <div className='uk-grid ' data-uk-grid>
                 <div className='uk-width-1-5@s'>
                   <img src={team2} className='team_members'/>
                 </div>

                 <div className='uk-width-4-5@s'>
                   <h6 className='uk-text-bold'>Nelson Uhembe</h6>
                   <p className='team_role uk-text-small'>Backend Engineer</p>

                   <p className='uk-margin-top'>Nelson is  a dedicated Backend Engineer with a passion 
                   for building robust and scalable systems that power seamless digital experiences. With a strong 
                   background in software development and a focus on backend technologies,he bring expertise in
                    crafting efficient, secure, and high-performance solutions for complex challenges</p>
                 </div>
            </div>
              </div>
                 </div>
             </div>
             



             


                 <div className='uk-grid'>
                      <div className='uk-width-4-5@s'>
                   <div className='team_container uk-margin-top'>
                   <div className='uk-grid ' data-uk-grid>
                 <div className='uk-width-1-5@s'>
                   <img src={team3} className='team_members_janet'/>
                 </div>

                 <div className='uk-width-4-5@s'>
                   <h6 className='uk-text-bold'>Janet Gbam</h6>
                   <p className='team_role uk-text-small'>Legal Advisory</p>

                   <p className='uk-margin-top uk-text-small'>Janet is seasoned lawyer and consultant. She is a human rights lawyer and a doctoral candidate in human
                     rights law.</p>

                     <p className='uk-margin-small-top uk-text-small'>A Mandela Washington Fellow and a Mandela Rhodes Scholar, She has more than ten 
                        years of experience in providing legal and consultancy, leading a network of 
                        pro-bono lawyers in Nigeria 
                     </p>
                 </div>
            </div>
              </div>
                      </div>

                      <div className='uk-width-1-5@s'>

                    </div> 
                 </div>





                <div className='uk-grid'>
                    <div className='uk-width-1-5@s'>

                    </div>

                    <div className='uk-width-4-5@s'>
                    <div className='team_container uk-margin-top'>
            <div className='uk-grid ' data-uk-grid>
                 <div className='uk-width-1-5@s'>
                   <img src={team4} className='team_members_janet'/>
                 </div>

                 <div className='uk-width-4-5@s'>
                   <h6 className='uk-text-bold'>Prince Ogbonna</h6>
                   <p className='team_role uk-text-small'> Advisory Board Chairman</p>

                   <p className='uk-margin-top uk-text-small'>Managing Partner, Moolu Venture Capital LP, Founder and
                    CEO @ Moolu Venture Lab, Convener @ E-tech Africa Summit, Emerald Pitchfest , NextTechCzar Africa</p>
                 </div>
            </div>
              </div>
                    </div>
                </div>



      
        </div>
        
    </div>
  )
}

export default Fifth_pane