import React from 'react'
import Layout from '../../Layout/Layout.js'
import Hero from './Blocks/Hero.js'
import First_pane from './Blocks/First_pane.js'
import Second_pane from './Blocks/Second_pane.js'
import Third_pane from './Blocks/Third_pane.js'
import Fourth_pane from './Blocks/Fourth_pane.js'
import Fifth_pane from './Blocks/Fifth_pane.js'

const Home = () => {
  return (
    <Layout>
    <Hero />
    <First_pane />
    <Second_pane />
    <Third_pane />
    <Fourth_pane />
    
  
    </Layout>
  )
}

export default Home