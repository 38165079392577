import React from 'react'
import intro_img_right from '../../../images/intro_img_right.png'
import {Link } from 'react-router-dom'

const Fourth_pane = () => {
  return (
    <div className=' uk-margin-xlarge-top'>
        
        <div className='uk-grid' data-uk-grid>
           

            <div className='uk-width-1-2@s'>
                <div className='moveRightFouthHome uk-padding'>
                   <h2 className='uk-text-left  uk-margin-large-top'>About Us</h2>
                   <p>We are group of professionals passionate about food shortage in Nigeria and
                     Sub-saharan Africa. Our aim is to revolutionize agriculture by controlling pests
                      and diseases with deep tech.</p>

                    <p className='uk-margin-top'>We are driven by a singular mission: to reform  the 
                    agricultural industry by reducing farm wastage, increasing yields for farmers, and providing an integrated platform
                     for efficient farm management and direct distribution of farm produce to consumers.</p>

                    <p className='uk-margin-top'></p>

                    <Link className="btn uk-margin-large-top" to="/about">Learn More</Link>

                    </div>

                   
            </div>


            <div className='uk-width-1-2@s'>
                <div className=''>
                
                <img src= {intro_img_right} class="intro_imgRight"/>
              
                </div>
            </div>
        </div>
    </div>
  )
}

export default Fourth_pane