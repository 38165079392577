import React from 'react'
import Pest from '../../../images/pc3.png'
import Farm_yield from '../../../images/pest_cntrol.png'
import Distr from '../../../images/distribution.png'

const Second_pane = () => {
  return (
    <div className='uk-margin-large-top '>
        <div className='uk-container uk-text-center'>

          <div className='uk-grid' data-uk-grid>
                <div className='uk-width-1-3@s'>
                  <img src={Pest}  className='blurb_img'/>
                  <h4 className='uk-margin-remove-top uk-text-bold'>Pest Control with AI</h4>
                  <p>Our AI technology helps to stop infestations, ensuring they are detected and 
                    controlled early. The algorithm processes vast amounts of data, such as weather
                     patterns, pest life cycles, and past outbreaks, to generate predictive models.</p>
                </div>

                <div className='uk-width-1-3@s'>
                <img src={Farm_yield}  className='blurb_img'/>
                  <h4 className='uk-margin-remove-top uk-text-bold'>Increased Farm Yield</h4>
                  <p>With precision agriculture in place, farmers are able to control pest and disease,
                     forecast prices and experience great harvest.Our Farm management system for farmers, 
                     enable them to create new farming seasons, monitor their farming operations.</p>
                </div>
         

                <div className='uk-width-1-3@s'>
                <img src={Distr}  className='blurb_img'/>
                  <h4 className='uk-margin-remove-top uk-text-bold'>Distribution & Technology</h4>
                  <p>Our goal is to disrupt the traditional food supply chain dominated by middlemen, 
                    ensuring that farm produce reaches consumers directly from the source.</p>
                </div>
        
          </div>

              

        </div>
    </div>
  )
}

export default Second_pane