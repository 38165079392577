import React from 'react'
import abt_imgs from '../../../images/abt_imgs.jpg'
import ahiaharvest from '../../../images/ahiaharvest.png'

const Second_pane = () => {
  return (
    <div className='uk-container uk-margin-xlarge-top'>

           <div className='uk-grid' data-uk-grid>
                <div className='uk-width-2-3@s'>
                    <div className=''>
                     <h1>Our Approach to Precision Agriculture</h1>
                     <p> With our method to combat pests effectively, our app  provides 
                         a comprehensive pest control management system. These tools offer
                         insights into pest patterns, recommend suitable pesticides, and 
                         facilitate timely interventions, ensuring crop protection and
                         minimizing losses. 
                         </p>

                    <p className='uk-margin-small-top'>Farmers can forecast prices of their produce, enhance their sales
                          process  by automating order management through our dedicated 
                          app(ahiavest). These tool simplify sales transactions, manage 
                          inventory, and give farmers a digital product marketing platform.</p>
                </div>
                </div>

                <div className='uk-width-1-3@s'>
                     <img src= {ahiaharvest} />
                </div>
           </div>


          
          <div className='uk-container'> 
               <div className='uk-grid uk-margin-large-top'>
                   <div className='uk-width-1-2@s'>
                   <img src= {abt_imgs} />
                   </div>

                   <div className='uk-width-1-2@s'>
                     <div className='uk-margin-xlarge-top'>
                         <h3>Our Mission</h3>
                         <p>Our mission is to revolutionize the agricultural landscape
                            by leveraging deep tech solutions. We envision a future 
                            where farmers have access to advanced tools, direct market
                            connections, efficient farm management systems, and financial 
                            support, enabling sustainable growth and prosperity.</p>
                     </div>
                   </div>
               </div>
          </div>




    </div>
  )
}

export default Second_pane