import React from 'react'
import './mainstyle.css'
import './App.css'

import { BrowserRouter, Routes,Route,  } from 'react-router-dom';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Contact from './Pages/Contact_us/Contact';

function App() {
  return (
    <BrowserRouter>
    <Routes>

      
    <Route  path="/" element={<Home />} />
    
    <Route  path="/about" element={<About />} />

    
    <Route  path="/contact" element={<Contact />} />
    
   




      </Routes>
      </BrowserRouter>
  );
}

export default App;
