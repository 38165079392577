import React from 'react'
import Layout from '../../Layout/Layout'
import First_pane from './Blocks/First_pane'

const Contact = () => {
  return (
    <Layout>
        <First_pane />

        </Layout>
  )
}

export default Contact