import React from 'react'
import HeroBg from '../../../images/home_banner.png'
import {Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className= ""
    
    style={{ 
    
      backgroundImage: `  url(${HeroBg})`,
      backgroundImage: ` url(${HeroBg})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      
      height: '1200px',
      paddingBottom:'100px',
     
  
  }}
    
    

    >
       
       <div className='uk-container '>
          <div className='uk-grid uk-margin-xlarge-top' data-uk-grid>
           <div className='uk-width-1-2@s'>
              <div className='uk-margin-large-top'>
                   
              </div>
           </div>

           <div className='uk-width-1-2@s'>
           <div className=' uk-margin-large-top' >
               <h1 className='light '>Precision Agriculture by Waax Africa</h1>
               <h6 className='light'>Our objective is to revolutionize agriculture in Africa through
                our innovative Federated Learning approach. With AI technology, we're tackling the
                 challenges of pests and diseases, ultimately boosting yields for smallholder farmers
                  across the continent  </h6>

                  <p className='light'>Given the pressing issue of food insecurity in Nigeria and Sub-Saharan Africa,
                     we're developing an integrated online platform that includes a Farm Management 
                     system,an ecommerce platform, and a distribution system.</p>

                     <p className='light uk-margin-top'>Our goal is to disrupt the traditional food supply chain dominated by middlemen, 
                      ensuring that farm produce reaches consumers directly from the source.</p>


               <div className='uk-flex uk-margin-large-top'>
              <div className=''>
                <Link to ='/about'  class="btn" >Learn More</Link>
              </div>

              <div className='uk-margin-small-top'>
                <a className='btn-ghost uk-margin-left' href=''>Visit Market Place</a>
              </div>
 
           </div>
               </div>
           </div>
           </div>
       </div>
    
   



    </div>
  )
}

export default Hero