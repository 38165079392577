import React from 'react'
import panecolor from '../../../images/panecolor.png'
import circle_png from '../../../images/circle_png.png'

const Third_pane = () => {
  return (
    <div className=''>
        <div
        
        style={{ 
    
            backgroundImage: `  url(${panecolor})`,
            backgroundImage: ` url(${panecolor})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            
            height: 'auto',
            paddingBottom:'50px',
           
        
        }}
          >

            <div className='uk-container '>
                 <div className='uk-grid' data-uk-grid>
                     <div className='uk-width-1-2@s uk-margin-xlarge-top'>

                         <h3 className='light'>Key Features and Solutions</h3>

                         <div className='uk-flex'>
                            <div><span class="uk-margin-small-right customIconz" uk-icon="check"></span></div>
                             <div><p className='light'><span className='uk-text-bold'>Direct-to-Consumer Sales:  </span> Enable farmers to sell their products directly to consumers, 
                              eliminating intermediaries and ensuring fair prices</p></div>
                          </div>

                          <div className='uk-flex uk-margin-medium-top'>
                            <div><span class="uk-margin-small-right customIconz" uk-icon="check"></span></div>
                             <div><p className='light'><span className='uk-text-bold'>Farm Management System: </span>Provide a comprehensive system for farm management, including crop monitoring,
                              inventory management, and resource optimization</p></div>
                          </div>

                          <div className='uk-flex uk-margin-medium-top'>
                            <div><span class="uk-margin-small-right customIconz" uk-icon="check"></span></div>
                             <div><p className='light'> <span className='uk-text-bold'>Credit Facility: </span>   Offer flexible credit options to customers, facilitating smooth transactions and supporting agricultural businesses</p></div>
                          </div>
                         
                     </div>

                     <div className='uk-width-1-2@s uk-margin-large-top'>
                        <img src= {circle_png} />
                     </div>
                 </div>
            </div>

        </div>
    </div>
  )
}

export default Third_pane