import React from 'react'
import about_banner from "../../../images/about_banner.png"
import pc3 from '../../../images/pc3.png'
import pest from '../../../images/pest_cntrol.png'

import crop_yield from '../../../images/crop_yield.png'

import distribution from '../../../images/distribution.png'

const FirstPane = () => {
  return (
    <div>
        <div className=''
        
        
        style={{ 
    
            backgroundImage: `  url(${about_banner})`,
            backgroundImage: ` url(${about_banner})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            
            height: '584px',
            paddingBottom:'100px',
           
        
        }}
          
        
        >

              <div className='uk-text-center '>
                <div className='title_header_pad'>
                    <h2 className='light ' >Increasing Yields of Smallholder Farmers in Africa</h2>
                    </div>
              </div>
        </div>

        <div className='uk-container'>

           <div className='uk-grid' data-uk-grid>
                <div className='uk-width-1-5@s'>

                </div>

                <div className='uk-width-3-5@s'>
                  
           <p className='uk-text-center '>Welcome to WAAX Africa, where we are transforming agriculture through cutting-edge 
            technology. Our integrated platform empowers farmers, connects them directly with
             consumers, streamlines farm management, and provides essential credit facilities.</p>
               </div>


               <div className='uk-width-1-5@s'>

               </div>
           </div>


        </div>


         <div className='uk-container uk-text-center'>
       <div className='uk-grid' data-uk-grid>
            <div className='uk-width-1-4@s'>
                <img src= {pc3}  className='blurb_img'/>
                <p className='uk-text-bold'>Pest Control with AI</p>
             </div>

             <div className='uk-width-1-4@s'>
             <img src= {pest}  className='blurb_img'/>
                <p className='uk-text-bold'>Increased Farm Yield</p>
             </div>

             <div className='uk-width-1-4@s'>
             
              <img src= {distribution}  className='blurb_img'/>
                <p className='uk-text-bold'>Distribution & Technology</p>
             </div>

             <div className='uk-width-1-4@s'>
             
             <img src= {crop_yield}  className='blurb_img'/>
                <p className='uk-text-bold'>Profits</p>
             </div>
             </div>
       </div>





    </div>
  )
}

export default FirstPane