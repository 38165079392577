import React from 'react'
import polo_print from '../../../images/polo_print.jpg'

const Fourth_pane = () => {
  return (
    <div className='uk-container uk-margin-xlarge-top'>

        <div className='uk-grid' data-uk-grid>
             <div className='uk-width-1-2@s'>
                 <div>
                    <img src= {polo_print} />
                    </div>
             </div>

             <div className='uk-width-1-2@s'>
                 <div>
                 <h3>Our Vision</h3>
                         <p>At WAAX Africa, we are committed to creating a 
                            sustainable and prosperous agricultural ecosystem. Our
                             goal is to empower farmers, promote food security, and
                              drive innovation in the industry. Together, we can
                               build a brighter future for agriculture.</p>
                    <h6 className='uk-margin-top uk-text-bold'>Learn more about our services, or contact our team for partnerships or inquiries.</h6>

                    <div className='uk-margin-medium-top'>
                        <a href="" className='btn'>Contact Us</a>
                    </div>
                </div>
             </div>
        </div>
        
    </div>
  )
}

export default Fourth_pane