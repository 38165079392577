import React from 'react'
import curvedBg from "../../../images/bg_second_pane.png"
import ahiaharvest from '../../../images/ahiaharvest.png'
import Checkmark from '../../../images/Checkmark.png'

import google_play_download from '../../../images/google_play_download.png'
import IOS_download from '../../../images/IOS_download.png'

const Third_pane = () => {
  return (
    <div className=''>
        <div
          className='curvedBgMobile'
          style={{ 
    
            backgroundImage: `  url(${curvedBg})`,
            backgroundImage: ` url(${curvedBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: '900px',
            paddingBottom:'100px',
            }}
          >

          <div className='uk-container '>
                  <div className='uk-grid TopMargin' data-uk-grid>
                      <div className='uk-width-1-2@s uk-margin-large-top uk-text-left'>
                         <a className='btn-2' href=""><span style={{fontWeight:"100"}}> Ahia</span><span style={{fontWeight:"bold"}}>Harvest </span></a>
                      <h3 className='light'>Farm Management System and Farmers gateway to crop distribution</h3>

                      <div className='uk-flex'>
                        <div className='checkbtn'> <img src={Checkmark} /> </div>
                        <div className='uk-margin-left'><p className='light'>  Farm management system to monitor farming operations </p></div>
                      </div>

                      <div className='uk-flex uk-margin-top'>
                        <div className='checkbtn'> <img src={Checkmark} /> </div>
                        <div className='uk-margin-left'><p className='light'>  Ecommerce and distribution system </p></div>
                      </div>

                      <div className='uk-flex  uk-margin-top'>
                        <div  className='checkbtn'> <img src={Checkmark}/> </div>
                        <div className='uk-margin-left'><p className='light'>  Credit facilities to access farm produce </p></div>
                      </div>

                      <div className='uk-flex  uk-margin-top'>
                        <div> <img src={google_play_download} className='downloadBtn'/> </div>
                        <div className='uk-margin-left'>  <img src={IOS_download} className='downloadBtn' /> </div>
                      </div>





                      </div>
                      

                      <div className='uk-width-1-2@s'>
                        <img src={ahiaharvest} />
                      </div>
                  </div>
          </div>


        </div>
    </div>
  )
}

export default Third_pane